.skills-background-bottom {
  width: 100%;
  background-color: #827f59;
}

.skills-section-wrapper {
  background-size: cover;
  width: 100%;
  background-color: #6f593e;

  @media screen and (min-width: 1300px) {
    height: 430px;
  }

  @media screen and (min-width: 1200px) and (max-width: 1299px) {
    height: 410px;
  }

  @media screen and (min-width: 1070px) and (max-width: 1199px) {
    height: 380px;
  }

  @media screen and (min-width: 1020px) and (max-width: 1069px) {
    height: 380px;
  }

  @media screen and (min-width: 992px) and (max-width: 1019px) {
    height: 350px;
  }

  @media screen and (min-width: 855px) and (max-width: 991px) {
    height: 330px;
  }

  @media screen and (min-width: 768px) and (max-width: 854px) {
    height: 350px;
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    height: 600px;
  }

  @media screen and (min-width: 400px) and (max-width: 575px) {
    height: 600px;
  }

  @media screen and (min-width: 330px) and (max-width: 399px) {
    height: 550px;
  }

  @media screen and (max-width: 329px) {
    height: 460px;
  }

  .skills-section {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85%;

    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
      width: auto;
    }

    .skills-content {
      @media screen and (min-width: 1300px) {
        padding-left: 20%;
      }

      @media screen and (min-width: 1200px) and (max-width: 1299px) {
        padding-left: 25%;
      }

      @media screen and (min-width: 1070px) and (max-width: 1199px) {
        padding-left: 15%;
      }

      @media screen and (min-width: 992px) and (max-width: 1069px) {
        padding-left: 20%;
      }

      @media screen and (min-width: 855px) and (max-width: 991px) {
        padding-left: 22%;
      }

      @media screen and (min-width: 815px) and (max-width: 854px) {
        padding-left: 18%;
      }

      @media screen and (min-width: 768px) and (max-width: 814px) {
        padding-left: 20%;
      }

      @media screen and (min-width: 576px) and (max-width: 767px) {
        padding-top: 50px;
      }

      @media screen and (min-width: 400px) and (max-width: 575px) {
        padding-top: 50px;
      }

      @media screen and (min-width: 330px) and (max-width: 399px) {
        padding-top: 40px;
      }

      @media screen and (max-width: 329px) {
        padding-top: 40px;
      }

      .skills-title {
        color: #fada9d;
        font-weight: 600;
        // margin-top: 60px;

        @media screen and (min-width: 1300px) {
          font-size: 32px;
        }

        @media screen and (min-width: 1200px) and (max-width: 1299px) {
          font-size: 28px;
        }

        @media screen and (min-width: 768px) and (max-width: 1199px) {
          font-size: 24px;
        }

        @media screen and (min-width: 400px) and (max-width: 767px) {
          font-size: 24px;
          margin-top: 0;
          text-align: center;
        }

        @media screen and (min-width: 330px) and (max-width: 399px) {
          font-size: 22px;
          margin-top: 0;
          text-align: center;
        }

        @media screen and (max-width: 329px) {
          font-size: 20px;
          margin-top: 0;
          text-align: center;
        }
      }

      .skills-text {
        color: #fada9d;
        font-weight: 500;
        margin-top: 30px;

        span {
          display: block;
          @media screen and (max-width: 767px) {
            padding-top: 8px;
          }
        }

        @media screen and (min-width: 1300px) {
          font-size: 18px;
          width: 430px;
        }

        @media screen and (min-width: 1200px) and (max-width: 1299px) {
          font-size: 16px;
          width: 430px;
        }

        @media screen and (min-width: 992px) and (max-width: 1199px) {
          font-size: 16px;
          width: 390px;
        }

        @media screen and (min-width: 855px) and (max-width: 991px) {
          font-size: 14px;
          width: 330px;
        }

        @media screen and (min-width: 768px) and (max-width: 854px) {
          font-size: 14px;
          width: 300px;
          // width: 350px;
        }

        @media screen and (min-width: 576px) and (max-width: 767px) {
          font-size: 14px;
          width: 350px;
          text-align: center;
        }

        @media screen and (min-width: 400px) and (max-width: 575px) {
          font-size: 14px;
          width: 350px;
          text-align: center;
          margin-bottom: 20px;
        }

        @media screen and (min-width: 380px) and (max-width: 399px) {
          font-size: 14px;
          width: 320px;
          text-align: center;
        }

        @media screen and (min-width: 330px) and (max-width: 379px) {
          font-size: 14px;
          width: 300px;
          text-align: center;
          margin-top: 20px;
        }

        @media screen and (max-width: 329px) {
          font-size: 12px;
          width: 260px;
          text-align: center;
          margin-top: 20px;
        }
      }

      .skills-text2-margin {
        margin-top: 0;
      }

      .skills-button-wrapper {
        @media screen and (max-width: 767px) {
          display: flex;
          justify-content: center;
        }

        .skills-button-border {
          margin-top: 30px;
          display: inline-block;
          background-color: #ffe0a6;
          border-radius: 8px;
          box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
          position: relative;
          z-index: 1;
          cursor: pointer;

          @media screen and (max-width: 575px) {
            margin-top: 10px;
          }

          .skills-button {
            display: inline-block;
            padding: 10px 30px;
            font-weight: 600;
            color: #ca8570;
            font-size: 16px;

            @media screen and (max-width: 329px) {
              font-size: 14px;
            }
          }
        }
      }
    }

    .skills-girl-img-wrapper {
      @media screen and (min-width: 1300px) {
        margin-left: 5%;
      }

      @media screen and (min-width: 1200px) and (max-width: 1299px) {
        margin-left: 3%;
        margin-right: 6%;
      }

      @media screen and (min-width: 1070px) and (max-width: 1199px) {
        margin-left: 3%;
      }

      @media screen and (min-width: 992px) and (max-width: 1069px) {
        margin-left: 0;
      }

      @media screen and (min-width: 895px) and (max-width: 991px) {
        margin-left: 5%;
      }

      @media screen and (min-width: 855px) and (max-width: 894px) {
        margin-left: 0;
      }

      @media screen and (min-width: 815px) and (max-width: 854px) {
        margin-left: 0;
      }

      @media screen and (min-width: 768px) and (max-width: 814px) {
        margin-left: 0;
      }

      @media screen and (min-width: 576px) and (max-width: 767px) {
        padding-top: 5%;
      }

      @media screen and (min-width: 400px) and (max-width: 575px) {
        padding-top: 5%;
      }

      @media screen and (min-width: 330px) and (max-width: 399px) {
        padding-top: 10%;
      }

      @media screen and (max-width: 329px) {
        padding-top: 8%;
      }

      .skills-girl-img {
        @media screen and (min-width: 1300px) {
          height: 470px;
        }

        @media screen and (min-width: 1200px) and (max-width: 1299px) {
          height: 450px;
        }

        @media screen and (min-width: 1070px) and (max-width: 1199px) {
          height: 410px;
        }

        @media screen and (min-width: 1020px) and (max-width: 1069px) {
          height: 370px;
        }

        @media screen and (min-width: 992px) and (max-width: 1019px) {
          height: 360px;
        }

        @media screen and (min-width: 855px) and (max-width: 991px) {
          height: 330px;
        }

        @media screen and (min-width: 768px) and (max-width: 854px) {
          height: 315px;
        }

        @media screen and (min-width: 576px) and (max-width: 767px) {
          height: 270px;
        }

        @media screen and (min-width: 400px) and (max-width: 575px) {
          height: 270px;
        }

        @media screen and (min-width: 330px) and (max-width: 399px) {
          height: 200px;
        }

        @media screen and (max-width: 329px) {
          height: 160px;
        }
      }
    }

    .skills-star-img-wrapper {
      position: absolute;

      @media screen and (min-width: 1300px) {
        left: 18%;
        bottom: -35%;
      }

      @media screen and (min-width: 1200px) and (max-width: 1299px) {
        left: 15%;
        bottom: -30%;
      }

      @media screen and (min-width: 1070px) and (max-width: 1199px) {
        left: 15%;
        bottom: -30%;
      }

      @media screen and (min-width: 1020px) and (max-width: 1069px) {
        left: 15%;
        bottom: -35%;
      }

      @media screen and (min-width: 992px) and (max-width: 1019px) {
        left: 15%;
        bottom: -35%;
      }

      @media screen and (min-width: 768px) and (max-width: 991px) {
        left: 15%;
        bottom: -35%;
      }

      @media screen and (min-width: 576px) and (max-width: 767px) {
        left: 10%;
        bottom: 0;
      }

      @media screen and (min-width: 450px) and (max-width: 575px) {
        left: 3%;
        bottom: 0;
      }

      @media screen and (min-width: 400px) and (max-width: 449px) {
        left: 5%;
        bottom: -5%;
      }

      @media screen and (min-width: 330px) and (max-width: 399px) {
        left: 5%;
        bottom: -5%;
      }

      @media screen and (max-width: 329px) {
        left: 5%;
        bottom: -5%;
      }

      .skills-star-img {
        @media screen and (min-width: 1300px) {
          height: 300px;
        }

        @media screen and (min-width: 1200px) and (max-width: 1299px) {
          height: 280px;
        }

        @media screen and (min-width: 1020px) and (max-width: 1199px) {
          height: 220px;
        }

        @media screen and (min-width: 992px) and (max-width: 1019px) {
          height: 200px;
        }

        @media screen and (min-width: 768px) and (max-width: 991px) {
          height: 180px;
        }

        @media screen and (min-width: 576px) and (max-width: 767px) {
          height: 200px;
        }

        @media screen and (min-width: 450px) and (max-width: 575px) {
          height: 150px;
        }

        @media screen and (min-width: 400px) and (max-width: 449px) {
          height: 125px;
        }

        @media screen and (min-width: 330px) and (max-width: 399px) {
          height: 125px;
        }

        @media screen and (max-width: 329px) {
          height: 100px;
        }
      }
    }

    .skills-star3-img-wrapper {
      position: absolute;

      @media screen and (min-width: 720px) and (max-width: 767px) {
        left: 65%;
        bottom: -15%;
      }

      @media screen and (min-width: 576px) and (max-width: 719px) {
        left: 63%;
        bottom: -15%;
      }

      @media screen and (min-width: 450px) and (max-width: 575px) {
        left: 63%;
        bottom: -15%;
      }

      @media screen and (min-width: 400px) and (max-width: 449px) {
        left: 63%;
        bottom: -13%;
      }

      @media screen and (min-width: 330px) and (max-width: 399px) {
        left: 63%;
        bottom: -15%;
      }

      @media screen and (max-width: 329px) {
        left: 65%;
        bottom: -13%;
      }

      .skills-star3-img {
        @media screen and (min-width: 720px) and (max-width: 767px) {
          height: 130px;
        }

        @media screen and (min-width: 576px) and (max-width: 719px) {
          height: 120px;
        }

        @media screen and (min-width: 450px) and (max-width: 575px) {
          height: 100px;
        }

        @media screen and (min-width: 400px) and (max-width: 449px) {
          height: 80px;
        }

        @media screen and (min-width: 330px) and (max-width: 399px) {
          height: 80px;
        }

        @media screen and (max-width: 329px) {
          height: 60px;
        }
      }
    }
  }
}
