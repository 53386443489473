.head-section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 700px;
  background-color: #fff4e5;

  @media screen and (min-width: 400px) and (max-width: 575px) {
    height: 600px;
  }

  @media screen and (min-width: 330px) and (max-width: 399px) {
    height: 550px;
  }

  @media screen and (max-width: 329px) {
    height: 500px;
  }

  .head-bar-wrapper {
    z-index: 10000;
    top: 0;
    left: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    transition: 200ms;

    .head-bar {
      z-index: 10001;
      display: flex;
      align-items: center;
      width: 1060px;
      justify-content: space-between;

      .logo-wrapper {
        height: 80px;
        margin: auto 30px auto 30px;

        .logo {
          height: 100%;
        }
      }

      .head-navbar {
        display: flex;
        align-items: center;

        .nav-tab {
          position: relative;
          font-size: 16px;
          font-weight: 600;
          margin: auto 30px;
          color: #6f2727;
          cursor: pointer;

          &::after {
            content: '';
            bottom: 0;
            left: 0;
            position: absolute;
            width: 0%;
            background-color: #704e4e;
            height: 1px;
            transition: width 0.1s linear;
          }
          &:hover {
            color: #661919;
            font-weight: 900;

            &::after {
              width: 100%;
            }
          }
        }
      }

      .resume-button-border {
        margin: auto 30px auto 30px;
        display: inline-block;
        border: 2px solid;
        border-color: #a67924;
        border-radius: 10px;
        cursor: pointer;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.25);

        .resume-button {
          display: inline-block;
          padding: 7px 20px;
          font-weight: 600;
          color: #a67924;
          border-radius: 8px;
          &:hover {
            background-color: #795f32;
            color: white;
            font-weight: 900;
          }
        }
      }

      .menu-icon {
        margin: auto 30px auto 30px;
        width: 30px;
        cursor: pointer;

        &.active {
          &:before {
            transform: translateY(10px) rotate(135deg);
          }

          &:after {
            transform: translateY(-10px) rotate(-135deg);
          }

          div {
            transform: scale(0);
          }
        }
      }

      .menu-icon:after,
      .menu-icon:before,
      .menu-icon div {
        background-color: #372107;
        content: '';
        display: block;
        height: 2px;
        margin: 8px 0;
        transition: all 0.2s ease-in-out;
      }
    }

    .mobile-nav {
      position: fixed;
      top: -100%;
      opacity: 0;
      height: 420px;
      width: 100%;
      background-color: #bdab8c;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.25);
      transition: top 0.7s cubic-bezier(0.82, 0.06, 0.5, 1), opacity 1s ease;
      -webkit-transition: top 0.7s cubic-bezier(0.82, 0.06, 0.5, 1),
        opacity 1s ease;

      &.active {
        top: 0;
        opacity: 1;
      }

      .mobile-nav-content-wrapper {
        margin-right: 30px;
        margin-left: 30px;

        .mobile-nav-content {
          line-height: 55px; //text vertically middle - need to same as height
          height: 55px;
          font-size: 32px;
          font-weight: 600;
          letter-spacing: 3px;
          // margin: auto;
          color: #593912;
          cursor: pointer;
        }
      }
    }

    .mobile-nav-resume-button-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 45px;

      .mobile-nav-resume-button-border {
        margin: auto 30px auto 30px;
        display: inline-block;
        border: 3px solid;
        border-color: #a67924;
        background-color: #ffe0a6;
        border-radius: 8px;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
        cursor: pointer;

        .mobile-nav-resume-button {
          display: inline-block;
          padding: 10px 30px;
          font-weight: 900;
          color: #a67924;
          font-size: 19px;
        }
      }
    }
  }

  .head-content {
    @media screen and (min-width: 1200px) {
      width: 1060px;
      margin-bottom: 100px;
      padding-left: 35px;
      margin-top: 5%;
    }

    @media screen and (min-width: 992px) and (max-width: 1199px) {
      width: 1060px;
      margin-bottom: 100px;
      padding-left: 110px;
      margin-top: 5%;
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
      width: 754px;
      margin-bottom: 100px;
      padding-left: 35px;
      margin-top: 5%;
    }

    @media screen and (min-width: 576px) and (max-width: 767px) {
      width: 561px;
      margin-bottom: 250px;
      text-align: center;
    }

    @media screen and (min-width: 400px) and (max-width: 575px) {
      width: 561px;
      margin-bottom: 150px;
      text-align: center;
    }

    @media screen and (min-width: 330px) and (max-width: 399px) {
      width: 561px;
      margin-bottom: 150px;
      text-align: center;
    }

    @media screen and (max-width: 329px) {
      width: 561px;
      margin-bottom: 130px;
      text-align: center;
    }

    .font-flower {
      font-family: 'Indie Flower';
    }

    .head-text-1 {
      color: #d14f26;

      @media screen and (min-width: 992px) {
        font-size: 60px;
        margin-bottom: 20px;
      }

      @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: 50px;
        margin-bottom: 25px;
      }

      @media screen and (min-width: 330px) and (max-width: 767px) {
        margin-bottom: 25px;
      }

      @media screen and (max-width: 329px) {
        font-size: 30px;
        margin-bottom: 25px;
      }
    }

    .head-text-2 {
      color: #a67924;

      @media screen and (min-width: 992px) {
        font-size: 35px;
        margin-bottom: 15px;
      }

      @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: 30px;
        margin-bottom: 20px;
      }

      @media screen and (min-width: 330px) and (max-width: 767px) {
        margin-bottom: 25px;
      }

      @media screen and (max-width: 329px) {
        font-size: 24px;
        margin-bottom: 25px;
      }
    }

    .head-text-3 {
      color: #967171;
      font-size: 24px;

      @media screen and (min-width: 992px) {
        margin-bottom: 0;
      }

      @media screen and (min-width: 768px) and (max-width: 991px) {
        margin-bottom: 5px;
      }
    }

    .head-text-4 {
      color: #967171;
      font-size: 24px;
      margin-bottom: 50px;
    }

    .more-button-border {
      margin: auto 10px auto 10px;
      display: inline-block;
      border: 2px solid;
      border-color: #a67924;
      border-radius: 10px;
      cursor: pointer;

      .more-button {
        display: inline-block;
        padding: 7px 30px;
        font-weight: 600;
        border-radius: 8px;
        color: #a67924;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.25);

        &:hover {
          background-color: #6b4708; //#6b4708;
          border-color: #493005;
          color: #e29505;
          font-weight: 900;
        }
      }
    }
  }

  $img-top-extra-large: 240px;
  $img-top-large: 270px;
  $img-top-medium: 320px;
  $img-top-small: 350px;
  $img-top-extra-small: 320px;
  $img-top-double-extra-small: 290px;

  $img-height-extra-large: 380px;
  $img-height-large: 350px;
  $img-height-medium: 300px;
  $img-height-small: 250px;
  $img-height-extra-small: 200px;
  $img-height-double-extra-small: 170px;

  .head-girl-img-wrapper {
    position: absolute;
    left: 50%;

    @media screen and (min-width: 1200px) {
      top: $img-top-extra-large;
      -webkit-transition: all 0.3s ease;
    }

    @media screen and (min-width: 992px) and (max-width: 1199px) {
      top: $img-top-large;
      -webkit-transition: all 0.3s ease;
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
      top: $img-top-medium;
      -webkit-transition: all 0.3s ease;
    }

    @media screen and (min-width: 576px) and (max-width: 767px) {
      top: $img-top-small;
      transform: translateX(-50%);
      -webkit-transition: all 0.3s ease;
    }

    @media screen and (min-width: 400px) and (max-width: 575px) {
      top: $img-top-small;
      transform: translateX(-50%);
      -webkit-transition: all 0.3s ease;
    }

    @media screen and (min-width: 330px) and (max-width: 399px) {
      top: $img-top-extra-small;
      transform: translateX(-50%);
      -webkit-transition: all 0.3s ease;
    }

    @media screen and (max-width: 329px) {
      top: $img-top-double-extra-small;
      transform: translateX(-50%);
      -webkit-transition: all 0.3s ease;
    }

    .head-girl-img {
      @media screen and (min-width: 1200px) {
        height: $img-height-extra-large;
      }

      @media screen and (min-width: 992px) and (max-width: 1199px) {
        height: $img-height-large;
      }

      @media screen and (min-width: 768px) and (max-width: 991px) {
        height: $img-height-medium;
      }

      @media screen and (min-width: 576px) and (max-width: 767px) {
        height: $img-height-small;
      }

      @media screen and (min-width: 400px) and (max-width: 575px) {
        height: $img-height-extra-small;
      }

      @media screen and (min-width: 330px) and (max-width: 399px) {
        height: $img-height-extra-small;
      }

      @media screen and (max-width: 329px) {
        height: $img-height-double-extra-small;
      }
    }
  }

  .head-girl-line {
    &::before {
      content: '';
      position: absolute;
      background-color: #583809;
      height: 1px;
      width: calc(100% - 60px);
      left: 50%;
      transform: translateX(-50%);

      @media screen and (min-width: 1200px) {
        width: 1000px;
        top: $img-top-extra-large + $img-height-extra-large - 2px;
      }

      @media screen and (min-width: 992px) and (max-width: 1199px) {
        top: $img-top-large + $img-height-large - 2px;
      }

      @media screen and (min-width: 768px) and (max-width: 991px) {
        top: $img-top-medium + $img-height-medium - 2px;
      }

      @media screen and (min-width: 576px) and (max-width: 767px) {
        top: $img-top-small + $img-height-small - 2px;
      }

      @media screen and (min-width: 400px) and (max-width: 575px) {
        top: $img-top-small + $img-height-extra-small - 2px;
      }

      @media screen and (min-width: 330px) and (max-width: 399px) {
        top: $img-top-extra-small + $img-height-extra-small - 2px;
      }

      @media screen and (max-width: 329px) {
        top: $img-top-double-extra-small + $img-height-double-extra-small - 2px;
      }
    }
  }

  .head-star-img-wrapper {
    position: absolute;
    left: 75%;
    z-index: 1000;

    @media screen and (min-width: 1500px) {
      left: 60%;
      top: $img-top-extra-large + $img-height-extra-large - 2px;
    }

    @media screen and (min-width: 1200px) and (max-width: 1499px) {
      top: $img-top-extra-large + $img-height-extra-large - 2px;
    }

    @media screen and (min-width: 992px) and (max-width: 1199px) {
      top: $img-top-large + $img-height-large - 2px;
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
      top: $img-top-medium + $img-height-medium - 2px;
    }

    @media screen and (min-width: 576px) and (max-width: 767px) {
      top: $img-top-small + $img-height-small - 2px;
    }

    @media screen and (min-width: 400px) and (max-width: 575px) {
      top: $img-top-small + $img-height-extra-small - 2px;
    }

    @media screen and (min-width: 330px) and (max-width: 399px) {
      top: $img-top-extra-small + $img-height-extra-small - 2px;
    }

    @media screen and (max-width: 329px) {
      top: $img-top-double-extra-small + $img-height-double-extra-small - 2px;
    }

    .head-star-img {
      @media screen and (min-width: 1200px) {
        height: 300px;
      }

      @media screen and (min-width: 992px) and (max-width: 1199px) {
        height: 280px;
      }

      @media screen and (min-width: 768px) and (max-width: 991px) {
        height: 260px;
      }

      @media screen and (min-width: 576px) and (max-width: 767px) {
        height: 200px;
      }

      @media screen and (min-width: 400px) and (max-width: 575px) {
        height: 130px;
      }

      @media screen and (min-width: 330px) and (max-width: 399px) {
        height: 100px;
      }

      @media screen and (max-width: 329px) {
        height: 80px;
      }
    }
  }
}
