.experience-item-section {
  color: #144416;
  border-radius: 5px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  @media screen and (min-width: 1300px) {
    width: 500px;
    height: 500px;
  }

  @media screen and (min-width: 1200px) and (max-width: 1299px) {
    width: 470px;
    height: 470px;
  }

  @media screen and (min-width: 1070px) and (max-width: 1199px) {
    width: 450px;
    height: 450px;
  }

  @media screen and (min-width: 1020px) and (max-width: 1069px) {
    width: 430px;
    height: 430px;
  }

  @media screen and (min-width: 992px) and (max-width: 1019px) {
    width: 400px;
    height: 400px;
  }

  @media screen and (min-width: 855px) and (max-width: 991px) {
    width: 380px;
    height: 380px;
  }

  @media screen and (min-width: 768px) and (max-width: 854px) {
    width: 450px;
    height: 450px;
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    width: 430px;
    height: 430px;
  }

  @media screen and (min-width: 500px) and (max-width: 575px) {
    width: 380px;
    height: 380px;
  }

  @media screen and (min-width: 400px) and (max-width: 499px) {
    width: 100vw;
    height: 320px;
    border-radius: 0;
    box-shadow: none;
  }

  @media screen and (min-width: 330px) and (max-width: 399px) {
    width: 100vw;
    height: 350px;
    border-radius: 0;
    box-shadow: none;
  }

  @media screen and (max-width: 329px) {
    width: 100vw;
    height: 420px;
    border-radius: 0;
    box-shadow: none;
  }

  .experience-item-wrapper {
    padding-left: 50px;
    padding-right: 50px;

    @media screen and (min-width: 400px) and (max-width: 449px) {
      padding-left: 35px;
      padding-right: 35px;
    }

    @media screen and (min-width: 330px) and (max-width: 399px) {
      padding-left: 40px;
      padding-right: 40px;
    }

    @media screen and (max-width: 329px) {
      padding-left: 45px;
      padding-right: 45px;
    }

    .experience-item-date {
      font-weight: 600;
      padding-bottom: 30px;

      @media screen and (min-width: 1300px) {
        font-size: 32px;
      }

      @media screen and (min-width: 1070px) and (max-width: 1299px) {
        font-size: 28px;
      }

      @media screen and (min-width: 992px) and (max-width: 1069px) {
        font-size: 24px;
      }

      @media screen and (min-width: 855px) and (max-width: 991px) {
        font-size: 20px;
      }

      @media screen and (min-width: 768px) and (max-width: 854px) {
        font-size: 32px;
      }

      @media screen and (min-width: 576px) and (max-width: 767px) {
        font-size: 28px;
        padding-bottom: 20px;
      }

      @media screen and (min-width: 450px) and (max-width: 575px) {
        font-size: 24px;
        padding-bottom: 20px;
      }

      @media screen and (min-width: 400px) and (max-width: 449px) {
        font-size: 22px;
        padding-bottom: 15px;
      }

      @media screen and (max-width: 399px) {
        font-size: 20px;
        padding-bottom: 15px;
      }
    }

    .experience-item-content {
      font-weight: 500;
      padding-bottom: 30px;

      @media screen and (min-width: 1070px) {
        font-size: 18px;
      }

      @media screen and (min-width: 1020px) and (max-width: 1069px) {
        font-size: 16px;
      }

      @media screen and (min-width: 992px) and (max-width: 1019px) {
        font-size: 15px;
      }

      @media screen and (min-width: 855px) and (max-width: 991px) {
        font-size: 14px;
      }

      @media screen and (min-width: 576px) and (max-width: 854px) {
        font-size: 18px;
        padding-bottom: 15px;
      }

      @media screen and (min-width: 450px) and (max-width: 575px) {
        font-size: 15px;
        padding-bottom: 15px;
      }

      @media screen and (max-width: 449px) {
        font-size: 15px;
        padding-bottom: 10px;
      }
    }

    .experience-item-content-addon {
      font-weight: 500;
      color: #654622;

      @media screen and (min-width: 1070px) {
        font-size: 18px;
      }

      @media screen and (min-width: 1020px) and (max-width: 1069px) {
        font-size: 16px;
      }

      @media screen and (min-width: 992px) and (max-width: 1019px) {
        font-size: 15px;
      }

      @media screen and (min-width: 855px) and (max-width: 991px) {
        font-size: 14px;
      }

      @media screen and (min-width: 576px) and (max-width: 854px) {
        font-size: 18px;
      }

      @media screen and (max-width: 575px) {
        font-size: 15px;
      }
    }

    .text-deco {
      text-decoration: underline;
    }
  }
}
