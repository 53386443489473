.footer-background-top {
  width: 100%;
  background-color: #fff1b0;
}

.footer-background-bottom {
  width: 100%;
  background-color: #fff1b0;
}

.footer-section-wrapper {
  background-size: cover;
  width: 100%;
  background-color: #fff1b0;
  position: relative;

  @media screen and (min-width: 1300px) {
    height: 500px;
  }

  @media screen and (min-width: 1200px) and (max-width: 1299px) {
    height: 450px;
  }

  @media screen and (min-width: 992px) and (max-width: 1199px) {
    height: 400px;
  }

  @media screen and (min-width: 330px) and (max-width: 991px) {
    height: 350px;
  }

  @media screen and (max-width: 329px) {
    height: 250px;
  }

  .footer-section {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .contact-section {
      display: flex;
      justify-content: center;

      @media screen and (min-width: 1300px) {
        margin-top: 250px;
      }

      @media screen and (min-width: 992px) and (max-width: 1299px) {
        margin-top: 200px;
      }

      @media screen and (min-width: 855px) and (max-width: 991px) {
        margin-top: 160px;
      }

      @media screen and (min-width: 330px) and (max-width: 854px) {
        margin-top: 130px;
      }

      @media screen and (max-width: 329px) {
        margin-top: 100px;
      }

      .contact-wrapper {
        text-align: center;
        margin-left: 100px;
        margin-right: 100px;
        z-index: 1;
        cursor: pointer;

        @media screen and (min-width: 768px) and (max-width: 991px) {
          margin-left: 80px;
          margin-right: 80px;
        }

        @media screen and (min-width: 576px) and (max-width: 767px) {
          margin-left: 60px;
          margin-right: 60px;
        }

        @media screen and (min-width: 450px) and (max-width: 575px) {
          margin-left: 20%;
          margin-right: 20%;
        }

        @media screen and (min-width: 350px) and (max-width: 449px) {
          margin-left: 15%;
          margin-right: 15%;
        }

        @media screen and (min-width: 330px) and (max-width: 349px) {
          margin-left: 10%;
          margin-right: 10%;
        }

        @media screen and (max-width: 329px) {
          margin-left: 20%;
          margin-right: 20%;
        }

        .linkedIn-img-wrapper {
          height: 50px;
          margin: -3px 0 23px 0;

          @media screen and (max-width: 575px) {
            margin-bottom: 10px;
          }
        }
        .email-img-wrapper {
          height: 50px;
          margin: 7px 0 13px 0;

          @media screen and (max-width: 575px) {
            margin-bottom: 0;
          }
        }
        .whatsapp-img-wrapper {
          height: 50px;
          margin: 0 0 20px 0;

          @media screen and (max-width: 575px) {
            margin-bottom: 7px;
          }
        }

        .contact-img {
          @media screen and (min-width: 768px) {
            width: 50px;
          }

          @media screen and (min-width: 576px) and (max-width: 767px) {
            width: 40px;
          }

          @media screen and (max-width: 575px) {
            width: 35px;
          }
        }

        .contact-text {
          color: #144416;
          font-weight: bold;
          text-decoration: underline !important;

          @media screen and (min-width: 1200px) {
            font-size: 32px;
          }

          @media screen and (min-width: 1070px) and (max-width: 1199px) {
            font-size: 28px;
          }

          @media screen and (min-width: 992px) and (max-width: 1069px) {
            font-size: 24px;
          }

          @media screen and (min-width: 576px) and (max-width: 991px) {
            font-size: 20px;
          }

          @media screen and (max-width: 575px) {
            font-size: 18px;
          }
        }
      }
    }

    .back-top-button-border {
      position: absolute;
      top: 125%;
      left: 85%;
      z-index: 1;

      animation-duration: 2s;
      animation-iteration-count: infinite;
      transform-origin: bottom;
      animation-name: jump;
      animation-timing-function: linear;

      &:hover {
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
      }

      .back-top-button {
        cursor: pointer;

        .back-top-img {
          @media screen and (min-width: 1200px) {
            width: 50px;
          }

          @media screen and (min-width: 855px) and (max-width: 1199px) {
            width: 45px;
          }

          @media screen and (min-width: 768px) and (max-width: 854px) {
            width: 40px;
          }

          @media screen and (min-width: 576px) and (max-width: 767px) {
            width: 35px;
          }

          @media screen and (max-width: 575px) {
            width: 30px;
          }
        }
      }
    }

    .jump {
      animation-name: jump;
      animation-timing-function: linear;
    }

    @keyframes jump {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-30px);
      }
      100% {
        transform: translateY(0);
      }
    }
  }

  .footer-large-cloud-img {
    // animation: move 5s linear 5s infinite;

    @media screen and (min-width: 1300px) {
      height: 90px;
    }

    @media screen and (min-width: 1200px) and (max-width: 1299px) {
      height: 70px;
    }

    @media screen and (min-width: 992px) and (max-width: 1199px) {
      height: 60px;
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
      height: 50px;
    }

    @media screen and (max-width: 767px) {
      height: 30px;
    }
  }

  .footer-medium-cloud-img {
    @media screen and (min-width: 1300px) {
      height: 50px;
    }

    @media screen and (min-width: 1200px) and (max-width: 1299px) {
      height: 40px;
    }

    @media screen and (min-width: 768px) and (max-width: 1199px) {
      height: 30px;
    }

    @media screen and (max-width: 767px) {
      height: 20px;
    }
  }

  .footer-small-cloud-img {
    @media screen and (min-width: 1300px) {
      height: 30px;
    }

    @media screen and (min-width: 1200px) and (max-width: 1299px) {
      height: 25px;
    }

    @media screen and (min-width: 768px) and (max-width: 1199px) {
      height: 20px;
    }

    @media screen and (max-width: 767px) {
      height: 15px;
    }
  }

  .footer-cloud-group-img {
    @media screen and (min-width: 1300px) {
      height: 40px;
    }

    @media screen and (min-width: 1200px) and (max-width: 1299px) {
      height: 30px;
    }

    @media screen and (min-width: 855px) and (max-width: 1199px) {
      height: 25px;
    }

    @media screen and (min-width: 768px) and (max-width: 854px) {
      height: 20px;
    }

    @media screen and (min-width: 576px) and (max-width: 767px) {
      height: 15px;
    }

    @media screen and (max-width: 575px) {
      height: 10px;
    }
  }

  @keyframes cloudMoveRight {
    from {
      left: -30%;
    }
    to {
      left: 100%;
    }
  }

  @keyframes cloudMoveLeft {
    from {
      left: 130%;
    }
    to {
      left: -30%;
    }
  }

  //cloud1
  .footer-cloud1-img-wrapper-initial {
    position: absolute;
    left: 100%;
    top: 5%;
    animation-iteration-count: 1;
    animation: cloud1MoveInitial 40s linear;

    @media screen and (max-width: 854px) {
      animation: cloud1MoveInitial2 40s linear;
    }
  }

  @keyframes cloud1MoveInitial {
    from {
      left: 35%;
    }
    to {
      left: 100%;
    }
  }

  @keyframes cloud1MoveInitial2 {
    from {
      left: 60%;
    }
    to {
      left: 100%;
    }
  }

  .footer-cloud1-img-wrapper {
    position: absolute;
    left: -30%;
    top: 5%;
    animation: cloudMoveRight 50s linear 15s infinite;
  }

  //cloud2
  .footer-cloud2-img-wrapper-initial {
    position: absolute;
    // left: -5%;
    left: 100%;
    top: 90%;
    animation-iteration-count: 1;
    animation: cloud2MoveInitial 40s linear;

    @media screen and (max-width: 854px) {
      // left: 20%;
      top: 85%;
      animation: cloud2MoveInitial2 40s linear;
    }
  }

  @keyframes cloud2MoveInitial {
    from {
      left: -5%;
    }
    to {
      left: 100%;
    }
  }

  @keyframes cloud2MoveInitial2 {
    from {
      left: 20%;
    }
    to {
      left: 100%;
    }
  }

  .footer-cloud2-img-wrapper {
    position: absolute;
    left: -30%;
    top: 90%;
    animation: cloudMoveRight 50s linear 30s infinite;
  }

  //cloud3
  .footer-cloud3-img-wrapper-initial {
    position: absolute;
    left: -30%;
    top: 70%;
    animation-iteration-count: 1;
    animation: cloud3MoveInitial 30s linear;

    // @media screen and (max-width: 854px) {
    //   top: 85%;
    //   animation: cloud3MoveInitial2 10s linear;
    // }
  }

  @keyframes cloud3MoveInitial {
    from {
      left: 80%;
    }
    to {
      left: -30%;
    }
  }

  // @keyframes cloud3MoveInitial2 {
  //   from {
  //     left: 20%;
  //   }
  //   to {
  //     left: 100%;
  //   }
  // }
  .footer-cloud3-img-wrapper {
    position: absolute;
    // left: -30%;
    left: 130%;
    top: 70%;
    transform: scaleX(-1);
    animation: cloudMoveLeft 40s linear 15s infinite;
  }

  //cloud4
  .footer-cloud4-img-wrapper-initial {
    position: absolute;
    left: 100%;
    top: 30%;
    animation-iteration-count: 1;
    animation: cloud4MoveInitial 30s linear;

    // @media screen and (max-width: 854px) {
    //   // left: 20%;
    //   top: 85%;
    //   animation: cloud4MoveInitial2 10s linear;
    // }
  }

  @keyframes cloud4MoveInitial {
    from {
      left: 5%;
    }
    to {
      left: 100%;
    }
  }

  // @keyframes cloud4MoveInitial2 {
  //   from {
  //     left: 20%;
  //   }
  //   to {
  //     left: 100%;
  //   }
  // }

  .footer-cloud4-img-wrapper {
    position: absolute;
    left: -30%;
    top: 30%;
    animation: cloudMoveRight 40s linear 15s infinite;
  }

  //cloud5
  .footer-cloud5-img-wrapper-initial {
    position: absolute;
    left: -30%;
    top: 90%;
    animation-iteration-count: 1;
    animation: cloud5MoveInitial 50s linear;
  }

  @keyframes cloud5MoveInitial {
    from {
      left: 50%;
    }
    to {
      left: -30%;
    }
  }

  .footer-cloud5-img-wrapper {
    position: absolute;
    left: 130%;
    top: 90%;
    transform: scaleX(-1);
    animation: cloudMoveLeft 60s linear 15s infinite;
  }

  //cloud6
  .footer-cloud6-img-wrapper-initial {
    position: absolute;
    left: -30%;
    top: 30%;
    z-index: 0;
    animation-iteration-count: 1;
    animation: cloud6MoveInitial 30s linear;

    @media screen and (max-width: 854px) {
      top: 70%;
      animation: cloud6MoveInitial2 40s linear;
    }
  }

  @keyframes cloud6MoveInitial {
    from {
      left: 70%;
    }
    to {
      left: -30%;
    }
  }

  @keyframes cloud6MoveInitial2 {
    from {
      left: 80%;
    }
    to {
      left: -30%;
    }
  }

  .footer-cloud6-img-wrapper {
    position: absolute;
    left: 130%;
    top: 30%;
    z-index: 0;
    animation: cloudMoveLeft 40s linear 10s infinite;
  }

  //cloud7
  .footer-cloud7-img-wrapper-initial {
    position: absolute;
    left: -30%;
    top: 130%;
    animation-iteration-count: 1;
    animation: cloud7MoveInitial 40s linear;

    @media screen and (min-width: 576px) and (max-width: 854px) {
      top: 120%;
    }

    @media screen and (max-width: 575px) {
      top: 115%;
    }
  }

  @keyframes cloud7MoveInitial {
    from {
      left: 30%;
    }
    to {
      left: -30%;
    }
  }

  .footer-cloud7-img-wrapper {
    position: absolute;
    left: 130%;
    top: 130%;
    animation: cloudMoveLeft 60s linear 10s infinite;

    @media screen and (min-width: 576px) and (max-width: 854px) {
      top: 120%;
    }

    @media screen and (max-width: 575px) {
      top: 115%;
    }
  }

  //cloud8
  .footer-cloud8-img-wrapper-initial {
    position: absolute;
    left: 100%;
    top: 145%;
    animation-iteration-count: 1;
    animation: cloud8MoveInitial 10s linear;

    @media screen and (min-width: 576px) and (max-width: 854px) {
      top: 130%;
    }

    @media screen and (max-width: 575px) {
      top: 120%;
    }
  }

  @keyframes cloud8MoveInitial {
    from {
      left: 88%;
    }
    to {
      left: 100%;
    }
  }

  .footer-cloud8-img-wrapper {
    position: absolute;
    left: -30%;
    top: 145%;
    transform: scaleX(-1);
    animation: cloudMoveRight 60s linear 5s infinite;

    @media screen and (min-width: 576px) and (max-width: 854px) {
      top: 130%;
    }

    @media screen and (max-width: 575px) {
      top: 120%;
    }
  }
}

.tooltip-inner {
  color: #fff1b0 !important;
  background-color: #2b2b2b !important;
}

.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #2b2b2b !important;
}

.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: #2b2b2b !important;
}

.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.bs-tooltip-left .arrow::before {
  border-left-color: #2b2b2b !important;
}

.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: #2b2b2b !important;
}
