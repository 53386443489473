.about-me-background-top {
  background-color: #fff4e5;

  @media screen and (min-width: 768px) {
    width: 100%;
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    width: 150%;
  }

  @media screen and (max-width: 575px) {
    width: 300%;
  }
}

.about-me-background-bottom {
  width: 100%;
  background-color: #ffe7ba;
}

.about-me-section-wrapper {
  background-size: cover;
  width: 100%;
  background-color: #ffe7ba;

  @media screen and (min-width: 1300px) {
    height: 680px;
  }

  @media screen and (min-width: 1200px) and (max-width: 1299px) {
    height: 620px;
  }

  @media screen and (min-width: 1070px) and (max-width: 1199px) {
    height: 600px;
  }

  @media screen and (min-width: 1020px) and (max-width: 1069px) {
    height: 580px;
  }

  @media screen and (min-width: 992px) and (max-width: 1019px) {
    height: 560px;
  }

  @media screen and (min-width: 855px) and (max-width: 991px) {
    height: 520px;
  }

  @media screen and (min-width: 768px) and (max-width: 854px) {
    height: 500px;
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    height: 650px;
  }

  @media screen and (min-width: 400px) and (max-width: 575px) {
    height: 550px;
  }

  @media screen and (min-width: 330px) and (max-width: 399px) {
    height: 480px;
  }

  @media screen and (max-width: 329px) {
    height: 400px;
  }

  .about-me-section {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85%;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      width: auto;
    }

    .about-me-girl-img-wrapper {
      padding-top: 12%;

      @media screen and (min-width: 1300px) {
        margin-left: 12%;
      }

      @media screen and (min-width: 1200px) and (max-width: 1299px) {
        margin-left: 14%;
      }

      @media screen and (min-width: 768px) and (max-width: 1199px) {
        margin-left: 16%;
      }

      @media screen and (min-width: 576px) and (max-width: 767px) {
        padding-top: 16%;
      }

      @media screen and (min-width: 400px) and (max-width: 575px) {
        padding-top: 8%;
      }

      @media screen and (min-width: 330px) and (max-width: 399px) {
        padding-top: 5%;
      }

      @media screen and (max-width: 329px) {
        padding-top: 4%;
      }

      .about-me-girl-img {
        @media screen and (min-width: 1300px) {
          height: 470px;
        }

        @media screen and (min-width: 1200px) and (max-width: 1299px) {
          height: 450px;
        }

        @media screen and (min-width: 1070px) and (max-width: 1199px) {
          height: 410px;
        }

        @media screen and (min-width: 1020px) and (max-width: 1069px) {
          height: 370px;
        }

        @media screen and (min-width: 992px) and (max-width: 1019px) {
          height: 330px;
        }

        @media screen and (min-width: 855px) and (max-width: 991px) {
          height: 300px;
        }

        @media screen and (min-width: 768px) and (max-width: 854px) {
          height: 300px;
        }

        @media screen and (min-width: 576px) and (max-width: 767px) {
          height: 270px;
        }

        @media screen and (min-width: 400px) and (max-width: 575px) {
          height: 250px;
        }

        @media screen and (min-width: 330px) and (max-width: 399px) {
          height: 200px;
        }

        @media screen and (max-width: 329px) {
          height: 150px;
        }
      }
    }

    .about-me-content {
      padding-top: 15%;
      padding-bottom: 100px;

      @media screen and (min-width: 1300px) {
        padding-left: 5%;
      }

      @media screen and (min-width: 1200px) and (max-width: 1299px) {
        padding-left: 7%;
      }

      @media screen and (min-width: 900px) and (max-width: 1199px) {
        padding-left: 9%;
      }

      @media screen and (min-width: 855px) and (max-width: 899px) {
        padding-left: 9%;
      }

      @media screen and (min-width: 768px) and (max-width: 854px) {
        padding-left: 7%;
      }

      @media screen and (min-width: 576px) and (max-width: 767px) {
        padding-top: 50px;
      }

      @media screen and (min-width: 400px) and (max-width: 575px) {
        padding-top: 40px;
      }

      @media screen and (min-width: 330px) and (max-width: 399px) {
        padding-top: 30px;
      }

      @media screen and (max-width: 329px) {
        padding-top: 30px;
      }

      .about-me-title {
        color: #a67924;
        font-weight: 600;
        margin-top: 100px;

        @media screen and (min-width: 1300px) {
          font-size: 32px;
        }

        @media screen and (min-width: 1200px) and (max-width: 1299px) {
          font-size: 28px;
        }

        @media screen and (min-width: 768px) and (max-width: 1199px) {
          font-size: 24px;
        }

        @media screen and (min-width: 400px) and (max-width: 767px) {
          font-size: 24px;
          margin-top: 0;
          text-align: center;
        }

        @media screen and (min-width: 330px) and (max-width: 399px) {
          font-size: 22px;
          margin-top: 0;
          text-align: center;
        }

        @media screen and (max-width: 329px) {
          font-size: 20px;
          margin-top: 0;
          text-align: center;
        }
      }

      .about-me-text {
        color: #a67924;
        font-weight: 500;
        margin-top: 30px;

        a {
          color: #6f2727;

          text-decoration: underline;
        }

        @media screen and (min-width: 1300px) {
          font-size: 18px;
          width: 430px;
        }

        @media screen and (min-width: 1200px) and (max-width: 1299px) {
          font-size: 16px;
          width: 430px;
        }

        @media screen and (min-width: 992px) and (max-width: 1199px) {
          font-size: 16px;
          width: 410px;
        }

        @media screen and (min-width: 855px) and (max-width: 991px) {
          font-size: 14px;
          width: 330px;
        }

        @media screen and (min-width: 768px) and (max-width: 854px) {
          font-size: 14px;
          width: 300px;
        }

        @media screen and (min-width: 576px) and (max-width: 767px) {
          font-size: 14px;
          width: 350px;
          text-align: center;
        }

        @media screen and (min-width: 400px) and (max-width: 575px) {
          font-size: 14px;
          width: 350px;
          text-align: center;
          margin-bottom: 20px;
        }

        @media screen and (min-width: 380px) and (max-width: 399px) {
          font-size: 14px;
          width: 320px;
          text-align: center;
        }

        @media screen and (min-width: 330px) and (max-width: 379px) {
          font-size: 14px;
          width: 300px;
          text-align: center;
          margin-top: 20px;
        }

        @media screen and (max-width: 329px) {
          font-size: 12px;
          width: 260px;
          text-align: center;
          margin-top: 20px;
        }
      }

      .about-me-button-wrapper {
        @media screen and (max-width: 767px) {
          display: flex;
          justify-content: center;
        }

        .about-me-button-border {
          margin-top: 30px;
          display: inline-block;
          background-color: #ffe0a6;
          border-radius: 8px;
          box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
          cursor: pointer;

          @media screen and (max-width: 575px) {
            margin-top: 10px;
          }

          .about-me-button {
            display: inline-block;
            padding: 10px 30px;
            font-weight: 600;
            color: #ca8570;
            font-size: 16px;
            border-radius: 8px;

            &:hover {
              background-color: #fdeac6;
              font-weight: 900;
            }

            @media screen and (max-width: 329px) {
              font-size: 14px;
            }
          }
        }
      }
    }

    .about-me-balls-img-wrapper {
      position: absolute;
      left: 5%;

      @media screen and (min-width: 1500px) {
        left: 20%;
        top: 3%;
      }

      @media screen and (min-width: 1200px) and (max-width: 1499px) {
        top: 3%;
      }

      @media screen and (min-width: 1020px) and (max-width: 1199px) {
        top: 6%;
      }

      @media screen and (min-width: 992px) and (max-width: 1019px) {
        top: 8%;
      }

      @media screen and (min-width: 768px) and (max-width: 991px) {
        top: 10%;
      }

      @media screen and (min-width: 576px) and (max-width: 767px) {
        top: 3%;
        left: 5%;
      }

      @media screen and (min-width: 400px) and (max-width: 575px) {
        top: 0;
        left: 8%;
      }

      @media screen and (min-width: 330px) and (max-width: 399px) {
        top: 0;
        left: 5%;
      }

      @media screen and (max-width: 329px) {
        top: 0;
      }

      .about-me-balls-img {
        @media screen and (min-width: 1300px) {
          height: 250px;
        }

        @media screen and (min-width: 1200px) and (max-width: 1299px) {
          height: 230px;
        }

        @media screen and (min-width: 1070px) and (max-width: 1199px) {
          height: 210px;
        }

        @media screen and (min-width: 1020px) and (max-width: 1069px) {
          height: 200px;
        }

        @media screen and (min-width: 768px) and (max-width: 1019px) {
          height: 190px;
        }

        @media screen and (min-width: 576px) and (max-width: 767px) {
          height: 190px;
        }

        @media screen and (min-width: 400px) and (max-width: 575px) {
          height: 150px;
        }

        @media screen and (min-width: 330px) and (max-width: 399px) {
          height: 110px;
        }

        @media screen and (max-width: 329px) {
          height: 100px;
        }
      }
    }
  }
}
