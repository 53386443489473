.experience-section-wrapper {
  background-size: cover;
  width: 100%;
  background-color: #827f59;
  position: relative;

  @media screen and (min-width: 1300px) {
    height: 3400px;
  }

  @media screen and (min-width: 1200px) and (max-width: 1299px) {
    height: 3200px;
  }

  @media screen and (min-width: 1070px) and (max-width: 1199px) {
    height: 3050px;
  }

  @media screen and (min-width: 1020px) and (max-width: 1069px) {
    height: 2900px;
  }

  @media screen and (min-width: 992px) and (max-width: 1019px) {
    height: 2750px;
  }

  @media screen and (min-width: 855px) and (max-width: 991px) {
    height: 2600px;
  }

  @media screen and (min-width: 768px) and (max-width: 854px) {
    height: 4400px;
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    height: 4200px;
  }

  @media screen and (min-width: 500px) and (max-width: 575px) {
    height: 3700px;
  }

  @media screen and (min-width: 330px) and (max-width: 499px) {
    height: 3400px;
  }

  @media screen and (max-width: 329px) {
    height: 3750px;
  }

  .experience-head-wrapper {
    @media screen and (max-width: 399px) {
      display: flex;
      justify-content: center;
    }
  }

  .experience-head {
    padding-top: 10%;
    padding-bottom: 8%;
    padding-right: 10%;
    text-align: right;

    @media screen and (min-width: 1300px) {
      padding-right: calc((100% - 500px * 2 - 50px * 2) / 2);
    }

    @media screen and (min-width: 1200px) and (max-width: 1299px) {
      padding-right: calc((100% - 470px * 2 - 50px * 2) / 2);
    }

    // @media screen and (min-width: 1070px) and (max-width: 1199px) {
    //   padding-right: 10%;
    // }

    // @media screen and (min-width: 1020px) and (max-width: 1069px) {
    //   padding-right: 10%;
    // }

    @media screen and (min-width: 992px) and (max-width: 1019px) {
      // padding-right: 10%;
      padding-bottom: 12%;
    }

    @media screen and (min-width: 855px) and (max-width: 991px) {
      // padding-right: 10%;
      padding-bottom: 12%;
    }

    @media screen and (min-width: 768px) and (max-width: 854px) {
      // padding-right: 10%;
      padding-bottom: 10%;
      padding-top: 15%;
    }

    @media screen and (min-width: 576px) and (max-width: 767px) {
      padding-right: 15%;
      padding-bottom: 3%;
      padding-top: 15%;
    }

    @media screen and (min-width: 400px) and (max-width: 575px) {
      padding-right: 70px;
      padding-bottom: 10%;
      padding-top: 15%;
    }

    @media screen and (min-width: 330px) and (max-width: 399px) {
      padding-bottom: 5%;
      padding-top: 15%;
    }

    @media screen and (max-width: 329px) {
      padding-bottom: 0;
      padding-top: 15%;
    }

    .experience-head-title {
      color: #364423;
      font-weight: 600;
      margin-bottom: 20px;

      @media screen and (min-width: 1200px) {
        font-size: 28px;
      }

      @media screen and (min-width: 768px) and (max-width: 1199px) {
        font-size: 24px;
      }

      @media screen and (min-width: 330px) and (max-width: 767px) {
        font-size: 20px;
      }

      @media screen and (max-width: 329px) {
        font-size: 16px;
      }
    }

    .experience-head-subtitle {
      color: #364423;
      font-weight: 600;
      margin-bottom: 20px;

      @media screen and (min-width: 1200px) {
        font-size: 42px;
      }

      @media screen and (min-width: 768px) and (max-width: 1199px) {
        font-size: 36px;
      }

      @media screen and (min-width: 576px) and (max-width: 767px) {
        font-size: 32px;
      }

      @media screen and (min-width: 330px) and (max-width: 575px) {
        font-size: 28px;
      }

      @media screen and (max-width: 329px) {
        font-size: 24px;
      }
    }
  }

  .experience-section {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .experience-content {
      display: flex;

      @media screen and (min-width: 1020px) {
        padding-top: 30px;
        justify-content: space-between;
      }

      @media screen and (min-width: 768px) and (max-width: 854px) {
        padding-top: 120px;
        flex-direction: column;
        justify-content: center;
      }

      @media screen and (min-width: 576px) and (max-width: 767px) {
        padding-top: 170px;
        flex-direction: column;
        justify-content: center;
      }

      @media screen and (max-width: 575px) {
        padding-top: 150px;
        flex-direction: column;
        justify-content: center;
      }

      .experience-content-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media screen and (min-width: 1070px) {
          padding-right: 50px;
        }

        @media screen and (min-width: 855px) and (max-width: 1069px) {
          padding-right: 30px;
        }
      }

      .experience-content-right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @media screen and (min-width: 1070px) {
          padding-left: 50px;
        }

        @media screen and (min-width: 855px) and (max-width: 1069px) {
          padding-left: 30px;
        }
      }
    }

    .space-between-items {
      @media screen and (min-width: 1300px) {
        width: 500px;
        height: 500px;
      }

      @media screen and (min-width: 1200px) and (max-width: 1299px) {
        width: 470px;
        height: 470px;
      }

      @media screen and (min-width: 1070px) and (max-width: 1199px) {
        width: 450px;
        height: 450px;
      }

      @media screen and (min-width: 1020px) and (max-width: 1069px) {
        width: 430px;
        height: 430px;
      }

      @media screen and (min-width: 992px) and (max-width: 1019px) {
        width: 400px;
        height: 400px;
      }

      @media screen and (min-width: 855px) and (max-width: 991px) {
        width: 380px;
        height: 380px;
      }

      @media screen and (min-width: 576px) and (max-width: 854px) {
        width: 1px;
        height: 250px;
      }

      @media screen and (min-width: 400px) and (max-width: 575px) {
        width: 1px;
        height: 200px;
      }

      @media screen and (max-width: 399px) {
        width: 1px;
        height: 175px;
      }
    }

    .timeline {
      background-color: #305950;
      width: 2px;

      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-content: space-between;

      @media screen and (min-width: 1300px) {
        height: 2000px;
        margin-top: 250px;
      }

      @media screen and (min-width: 1200px) and (max-width: 1299px) {
        height: 1880px;
        margin-top: 235px;
      }

      @media screen and (min-width: 1070px) and (max-width: 1199px) {
        height: 1800px;
        margin-top: 225px;
      }

      @media screen and (min-width: 1020px) and (max-width: 1069px) {
        height: 1720px;
        margin-top: 215px;
      }

      @media screen and (min-width: 992px) and (max-width: 1019px) {
        height: 1600px;
        margin-top: 200px;
      }

      @media screen and (min-width: 855px) and (max-width: 991px) {
        height: 1520px;
        margin-top: 190px;
      }

      .timeline-point {
        background-color: #305950;
        width: 20px;
        height: 20px;
        transform: translateX(-45%) translateY(0) rotate(45deg);
      }

      .start-point {
        background-color: #305950;
        width: 20px;
        height: 20px;
        transform: translateX(-45%) translateY(-50%) rotate(45deg);
      }

      .end-point {
        background-color: #305950;
        width: 20px;
        height: 20px;
        transform: translateX(-45%) translateY(+50%) rotate(45deg);
      }
    }
  }

  //figures
  .experience-line-img-wrapper {
    position: absolute;

    @media screen and (min-width: 1500px) {
      left: -6%;
      top: -12%;
    }

    @media screen and (min-width: 1200px) and (max-width: 1499px) {
      left: -6%;
      top: -8%;
    }

    @media screen and (min-width: 1070px) and (max-width: 1199px) {
      left: -8%;
      top: -7%;
    }

    @media screen and (min-width: 992px) and (max-width: 1069px) {
      left: -15%;
      top: -8%;
    }

    @media screen and (min-width: 855px) and (max-width: 991px) {
      left: -13%;
      top: -8%;
    }

    @media screen and (min-width: 768px) and (max-width: 854px) {
      left: -13%;
      top: -4%;
    }

    @media screen and (min-width: 576px) and (max-width: 767px) {
      left: -18%;
      top: -4%;
    }

    @media screen and (min-width: 500px) and (max-width: 575px) {
      left: -20%;
      top: -4%;
    }

    @media screen and (min-width: 450px) and (max-width: 499px) {
      left: -25%;
      top: -4%;
    }

    @media screen and (min-width: 400px) and (max-width: 449px) {
      left: -30%;
      top: -3%;
    }

    @media screen and (min-width: 350px) and (max-width: 399px) {
      left: -35%;
      top: -3%;
    }

    @media screen and (min-width: 330px) and (max-width: 349px) {
      left: -40%;
      top: -3%;
    }

    @media screen and (max-width: 329px) {
      left: -40%;
      top: -2.5%;
    }

    .experience-line-img {
      @media screen and (min-width: 1300px) {
        height: 500px;
      }

      @media screen and (min-width: 1200px) and (max-width: 1299px) {
        height: 470px;
      }

      @media screen and (min-width: 1070px) and (max-width: 1199px) {
        height: 450px;
      }

      @media screen and (min-width: 1020px) and (max-width: 1069px) {
        height: 430px;
      }

      @media screen and (min-width: 992px) and (max-width: 1019px) {
        height: 400px;
      }

      @media screen and (min-width: 855px) and (max-width: 991px) {
        height: 380px;
      }

      @media screen and (min-width: 768px) and (max-width: 854px) {
        height: 360px;
      }

      @media screen and (min-width: 576px) and (max-width: 767px) {
        height: 340px;
      }

      @media screen and (min-width: 450px) and (max-width: 575px) {
        height: 300px;
      }

      @media screen and (min-width: 330px) and (max-width: 449px) {
        height: 280px;
      }

      @media screen and (max-width: 329px) {
        height: 250px;
      }
    }
  }

  .experience-line2-img-wrapper {
    position: absolute;

    @media screen and (min-width: 1070px) {
      left: -3%;
      top: 65%;
    }

    @media screen and (min-width: 1020px) and (max-width: 1069px) {
      left: -5%;
      top: 65%;
    }

    @media screen and (min-width: 855px) and (max-width: 1019px) {
      left: -3%;
      top: 63%;
    }

    .experience-line2-img {
      @media screen and (min-width: 1300px) {
        height: 300px;
      }

      @media screen and (min-width: 1200px) and (max-width: 1299px) {
        height: 280px;
      }

      @media screen and (min-width: 1070px) and (max-width: 1199px) {
        height: 260px;
      }

      @media screen and (min-width: 1020px) and (max-width: 1069px) {
        height: 240px;
      }

      @media screen and (min-width: 992px) and (max-width: 1019px) {
        height: 220px;
      }

      @media screen and (min-width: 855px) and (max-width: 991px) {
        height: 200px;
      }
    }
  }

  .experience-stars-img-wrapper {
    position: absolute;

    @media screen and (min-width: 1500px) {
      left: 15%;
      top: 42%;
    }

    @media screen and (min-width: 1300px) and (max-width: 1499px) {
      left: 10%;
      top: 37%;
    }

    @media screen and (min-width: 1200px) and (max-width: 1299px) {
      left: 8%;
      top: 37%;
    }

    @media screen and (min-width: 1070px) and (max-width: 1199px) {
      left: 7%;
      top: 37%;
    }

    @media screen and (min-width: 1020px) and (max-width: 1069px) {
      left: 5%;
      top: 36%;
    }

    @media screen and (min-width: 855px) and (max-width: 1019px) {
      left: 5%;
      top: 38%;
    }

    .experience-stars-img {
      @media screen and (min-width: 1300px) {
        height: 140px;
      }

      @media screen and (min-width: 1070px) and (max-width: 1299px) {
        height: 120px;
      }

      @media screen and (min-width: 992px) and (max-width: 1069px) {
        height: 110px;
      }

      @media screen and (min-width: 855px) and (max-width: 991px) {
        height: 100px;
      }
    }
  }

  .experience-stars2-img-wrapper {
    position: absolute;

    @media screen and (min-width: 1500px) {
      left: 65%;
      top: 60%;
    }

    @media screen and (min-width: 855px) and (max-width: 1499px) {
      left: 70%;
      top: 56%;
    }

    @media screen and (min-width: 768px) and (max-width: 854px) {
      left: 75%;
      top: 67%;
    }

    @media screen and (min-width: 576px) and (max-width: 767px) {
      left: 75%;
      top: 65%;
    }

    @media screen and (min-width: 500px) and (max-width: 575px) {
      left: 80%;
      top: 66%;
    }

    @media screen and (min-width: 400px) and (max-width: 499px) {
      left: 80%;
      top: 65%;
    }

    @media screen and (min-width: 331px) and (max-width: 399px) {
      left: 80%;
      top: 62%;
    }

    @media screen and (max-width: 330px) {
      left: 75%;
      top: 62%;
    }

    .experience-stars2-img {
      @media screen and (min-width: 1300px) {
        height: 70px;
      }

      @media screen and (min-width: 1200px) and (max-width: 1299px) {
        height: 65px;
      }

      @media screen and (min-width: 1020px) and (max-width: 1199px) {
        height: 60px;
      }

      @media screen and (min-width: 855px) and (max-width: 1019px) {
        height: 50px;
      }

      @media screen and (min-width: 576px) and (max-width: 854px) {
        height: 60px;
      }

      @media screen and (min-width: 500px) and (max-width: 575px) {
        height: 50px;
      }

      @media screen and (max-width: 499px) {
        height: 40px;
      }
    }
  }

  .experience-stars3-img-wrapper {
    position: absolute;
    left: 5%;

    @media screen and (min-width: 1300px) {
      top: 105%;
    }

    @media screen and (min-width: 1020px) and (max-width: 1299px) {
      top: 103%;
    }

    @media screen and (min-width: 855px) and (max-width: 1019px) {
      top: 102%;
    }

    @media screen and (max-width: 854px) {
      top: 100%;
    }

    .experience-stars3-img {
      @media screen and (min-width: 1300px) {
        height: 100px;
      }

      @media screen and (min-width: 992px) and (max-width: 1299px) {
        height: 80px;
      }

      @media screen and (min-width: 855px) and (max-width: 991px) {
        height: 70px;
      }

      @media screen and (min-width: 768px) and (max-width: 854px) {
        height: 80px;
      }

      @media screen and (min-width: 576px) and (max-width: 767px) {
        height: 70px;
      }

      @media screen and (max-width: 575px) {
        height: 60px;
      }
    }
  }

  .experience-figure-img-wrapper {
    position: absolute;
    left: 75%;

    @media screen and (min-width: 1300px) {
      left: 50%;
      top: 93%;
    }

    @media screen and (min-width: 1200px) and (max-width: 1299px) {
      // left: 75%;
      top: 85%;
    }

    @media screen and (min-width: 1020px) and (max-width: 1199px) {
      // left: 75%;
      top: 87%;
    }

    @media screen and (min-width: 855px) and (max-width: 1019px) {
      // left: 75%;
      top: 83%;
    }

    .experience-figure-img {
      @media screen and (min-width: 1300px) {
        height: 250px;
      }

      @media screen and (min-width: 1200px) and (max-width: 1299px) {
        height: 280px;
      }

      @media screen and (min-width: 1070px) and (max-width: 1199px) {
        height: 260px;
      }

      @media screen and (min-width: 1020px) and (max-width: 1069px) {
        height: 240px;
      }

      @media screen and (min-width: 992px) and (max-width: 1019px) {
        height: 220px;
      }

      @media screen and (min-width: 855px) and (max-width: 991px) {
        height: 200px;
      }
    }
  }
}

//graduation item
.experience-graduated-item-padding {
  @media screen and (min-width: 1200px) {
    padding-top: 120px;
  }

  @media screen and (min-width: 992px) and (max-width: 1199px) {
    padding-top: 100px;
  }

  @media screen and (min-width: 855px) and (max-width: 991px) {
    padding-top: 90px;
  }

  @media screen and (min-width: 768px) and (max-width: 854px) {
    padding-top: 100px;
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    padding-top: 90px;
  }

  @media screen and (min-width: 500px) and (max-width: 575px) {
    padding-top: 85px;
  }

  @media screen and (min-width: 400px) and (max-width: 499px) {
    padding-top: 75px;
  }

  @media screen and (min-width: 330px) and (max-width: 399px) {
    padding-top: 80px;
  }

  @media screen and (max-width: 329px) {
    padding-top: 100px;
  }
}

.experience-graduated-girl-img-wrapper {
  position: relative;

  @media screen and (min-width: 1300px) {
    left: 10%;
    top: -163%;
  }

  @media screen and (min-width: 1200px) and (max-width: 1299px) {
    left: 10%;
    top: -171%;
  }

  @media screen and (min-width: 1070px) and (max-width: 1199px) {
    left: 13%;
    top: -169%;
  }

  @media screen and (min-width: 1020px) and (max-width: 1069px) {
    left: 16%;
    top: -159%;
  }

  @media screen and (min-width: 992px) and (max-width: 1019px) {
    left: 10%;
    top: -178%;
  }

  @media screen and (min-width: 855px) and (max-width: 991px) {
    left: 13%;
    top: -171%;
  }

  @media screen and (min-width: 768px) and (max-width: 854px) {
    left: 13%;
    top: -167%;
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    left: 16%;
    top: -159%;
  }

  @media screen and (min-width: 500px) and (max-width: 575px) {
    left: 13%;
    top: -163%;
  }

  @media screen and (min-width: 465px) and (max-width: 499px) {
    left: 10%;
    top: -175%;
  }

  @media screen and (min-width: 459px) and (max-width: 464px) {
    left: 10%;
    top: -183%;
  }

  @media screen and (min-width: 450px) and (max-width: 458px) {
    left: 10%;
    top: -183%;
  }

  @media screen and (min-width: 435px) and (max-width: 449px) {
    left: 5%;
    top: -170%;
  }

  @media screen and (min-width: 429px) and (max-width: 434px) {
    left: 5%;
    top: -177%;
  }

  @media screen and (min-width: 400px) and (max-width: 428px) {
    left: 5%;
    top: -178%;
  }

  @media screen and (min-width: 387px) and (max-width: 399px) {
    left: 10%;
    top: -156%;
  }

  @media screen and (min-width: 347px) and (max-width: 386px) {
    left: 10%;
    top: -165%;
  }

  @media screen and (min-width: 330px) and (max-width: 346px) {
    left: 10%;
    top: -173%;
  }

  @media screen and (max-width: 329px) {
    left: 15%;
    top: -158%;
  }
}

.experience-graduated-girl-img {
  @media screen and (min-width: 1300px) {
    height: 300px;
  }

  @media screen and (min-width: 1200px) and (max-width: 1299px) {
    height: 280px;
  }

  @media screen and (min-width: 1070px) and (max-width: 1199px) {
    height: 260px;
  }

  @media screen and (min-width: 992px) and (max-width: 1069px) {
    height: 240px;
  }

  @media screen and (min-width: 855px) and (max-width: 991px) {
    height: 220px;
  }

  @media screen and (min-width: 768px) and (max-width: 854px) {
    height: 260px;
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    height: 240px;
  }

  @media screen and (min-width: 400px) and (max-width: 575px) {
    height: 220px;
  }

  @media screen and (max-width: 399px) {
    height: 200px;
  }
}

//jll item
.experience-work-item-padding {
  @media screen and (min-width: 1200px) {
    padding-top: 70px;
  }

  @media screen and (min-width: 855px) and (max-width: 1199px) {
    padding-top: 50px;
  }

  @media screen and (min-width: 768px) and (max-width: 854px) {
    padding-top: 45px;
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    padding-top: 40px;
  }

  @media screen and (min-width: 500px) and (max-width: 575px) {
    padding-top: 50px;
  }

  @media screen and (min-width: 450px) and (max-width: 499px) {
    padding-top: 45px;
  }

  @media screen and (min-width: 417px) and (max-width: 449px) {
    padding-top: 40px;
  }

  @media screen and (min-width: 400px) and (max-width: 416px) {
    padding-top: 35px;
  }

  @media screen and (min-width: 386px) and (max-width: 399px) {
    padding-top: 50px;
  }

  @media screen and (min-width: 348px) and (max-width: 385px) {
    padding-top: 40px;
  }

  @media screen and (min-width: 335px) and (max-width: 347px) {
    padding-top: 30px;
  }

  @media screen and (min-width: 330px) and (max-width: 334px) {
    padding-top: 20px;
  }

  @media screen and (max-width: 329px) {
    padding-top: 55px;
  }
}

.experience-working-girls-img-wrapper {
  position: relative;

  @media screen and (min-width: 1300px) {
    left: 40%;
    top: -152.5%;
  }

  @media screen and (min-width: 1200px) and (max-width: 1299px) {
    left: 43%;
    top: -157%;
  }

  @media screen and (min-width: 1070px) and (max-width: 1199px) {
    left: 42%;
    top: -156%;
  }

  @media screen and (min-width: 1020px) and (max-width: 1069px) {
    left: 43%;
    top: -145%;
  }

  @media screen and (min-width: 992px) and (max-width: 1019px) {
    left: 41%;
    top: -156%;
  }

  @media screen and (min-width: 855px) and (max-width: 991px) {
    left: 41%;
    top: -157%;
  }

  @media screen and (min-width: 768px) and (max-width: 854px) {
    left: 40%;
    top: -151%;
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    left: 40%;
    top: -148%;
  }

  @media screen and (min-width: 500px) and (max-width: 575px) {
    left: 40%;
    top: -154.5%;
  }

  @media screen and (min-width: 495px) and (max-width: 499px) {
    left: 50%;
    top: -142%;
  }

  @media screen and (min-width: 452px) and (max-width: 494px) {
    left: 50%;
    top: -150%;
  }

  @media screen and (min-width: 450px) and (max-width: 451px) {
    left: 50%;
    top: -157%;
  }

  @media screen and (min-width: 422px) and (max-width: 449px) {
    left: 45%;
    top: -138%;
  }

  @media screen and (min-width: 400px) and (max-width: 421px) {
    left: 45%;
    top: -145%;
  }

  @media screen and (min-width: 391px) and (max-width: 399px) {
    left: 45%;
    top: -135%;
  }

  @media screen and (min-width: 386px) and (max-width: 390px) {
    left: 45%;
    top: -142%;
  }

  @media screen and (min-width: 353px) and (max-width: 385px) {
    left: 45%;
    top: -140%;
  }

  @media screen and (min-width: 348px) and (max-width: 352px) {
    left: 45%;
    top: -147%;
  }

  @media screen and (min-width: 330px) and (max-width: 347px) {
    left: 40%;
    top: -145%;
  }

  @media screen and (max-width: 329px) {
    left: 35%;
    top: -135%;
  }
}

.experience-working-girls-img {
  @media screen and (min-width: 1300px) {
    height: 235px;
  }

  @media screen and (min-width: 1200px) and (max-width: 1299px) {
    height: 215px;
  }

  @media screen and (min-width: 1070px) and (max-width: 1199px) {
    height: 205px;
  }

  @media screen and (min-width: 1020px) and (max-width: 1069px) {
    height: 190px;
  }

  @media screen and (min-width: 992px) and (max-width: 1019px) {
    height: 180px;
  }

  @media screen and (min-width: 855px) and (max-width: 991px) {
    height: 170px;
  }

  @media screen and (min-width: 768px) and (max-width: 854px) {
    height: 205px;
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    height: 190px;
  }

  @media screen and (min-width: 450px) and (max-width: 575px) {
    height: 170px;
  }

  @media screen and (min-width: 400px) and (max-width: 449px) {
    height: 160px;
  }

  @media screen and (max-width: 399px) {
    height: 150px;
  }
}

.experience-working-bag-img-wrapper {
  position: relative;
  left: 78%;

  @media screen and (min-width: 1300px) {
    left: 76%;
    top: -51%;
  }

  @media screen and (min-width: 1200px) and (max-width: 1299px) {
    left: 77%;
    top: -54%;
  }

  @media screen and (min-width: 1070px) and (max-width: 1199px) {
    left: 76%;
    top: -58.5%;
  }

  @media screen and (min-width: 1020px) and (max-width: 1069px) {
    // left: 78%;
    top: -45.5%;
  }

  @media screen and (min-width: 992px) and (max-width: 1019px) {
    left: 76%;
    top: -56.5%;
  }

  @media screen and (min-width: 855px) and (max-width: 991px) {
    left: 75%;
    top: -58%;
  }

  @media screen and (min-width: 768px) and (max-width: 854px) {
    left: 76%;
    top: -55%;
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    left: 75%;
    top: -52%;
  }

  @media screen and (min-width: 500px) and (max-width: 575px) {
    left: 75%;
    top: -55%;
  }
  @media screen and (min-width: 495px) and (max-width: 499px) {
    // left: 78%;
    top: -68%;
  }

  @media screen and (min-width: 450px) and (max-width: 494px) {
    // left: 78%;
    top: -76%;
  }

  @media screen and (min-width: 422px) and (max-width: 449px) {
    left: 75%;
    top: -61%;
  }

  @media screen and (min-width: 417px) and (max-width: 421px) {
    left: 75%;
    top: -69%;
  }

  @media screen and (min-width: 400px) and (max-width: 416px) {
    left: 75%;
    top: -66%;
  }

  @media screen and (min-width: 391px) and (max-width: 399px) {
    // left: 78%;
    top: -50%;
  }

  @media screen and (min-width: 386px) and (max-width: 390px) {
    // left: 78%;
    top: -58%;
  }

  @media screen and (min-width: 353px) and (max-width: 385px) {
    // left: 78%;
    top: -53%;
  }

  @media screen and (min-width: 348px) and (max-width: 352px) {
    // left: 78%;
    top: -60%;
  }

  @media screen and (min-width: 340px) and (max-width: 347px) {
    // left: 78%;
    top: -55%;
  }

  @media screen and (min-width: 335px) and (max-width: 339px) {
    // left: 78%;
    top: -57%;
  }

  @media screen and (min-width: 330px) and (max-width: 334px) {
    // left: 78%;
    top: -57%;
  }

  @media screen and (max-width: 329px) {
    left: 70%;
    top: -42%;
  }
}

.experience-working-bag-img {
  @media screen and (min-width: 1300px) {
    height: 100px;
  }

  @media screen and (min-width: 1070px) and (max-width: 1299px) {
    height: 90px;
  }

  @media screen and (min-width: 855px) and (max-width: 1069px) {
    height: 80px;
  }

  @media screen and (min-width: 576px) and (max-width: 854px) {
    height: 90px;
  }

  @media screen and (min-width: 500px) and (max-width: 575px) {
    height: 80px;
  }

  @media screen and (min-width: 450px) and (max-width: 499px) {
    height: 70px;
  }

  @media screen and (min-width: 400px) and (max-width: 449px) {
    height: 60px;
  }

  @media screen and (max-width: 399px) {
    height: 50px;
  }
}

//struggling item
.experience-struggle-item-padding {
  @media screen and (min-width: 1300px) {
    padding-top: 70px;
  }

  @media screen and (min-width: 1070px) and (max-width: 1299px) {
    padding-top: 60px;
  }

  @media screen and (min-width: 576px) and (max-width: 1069px) {
    padding-top: 50px;
  }

  @media screen and (min-width: 500px) and (max-width: 575px) {
    padding-top: 40px;
  }

  @media screen and (min-width: 400px) and (max-width: 499px) {
    padding-top: 60px;
  }

  @media screen and (min-width: 345px) and (max-width: 399px) {
    padding-top: 70px;
  }

  @media screen and (min-width: 330px) and (max-width: 344px) {
    padding-top: 55px;
  }

  @media screen and (max-width: 329px) {
    padding-top: 80px;
  }
}

.experience-struggling-girl-img-wrapper {
  position: relative;
  left: 0;

  @media screen and (min-width: 1300px) {
    // left: 0;
    top: -130%;
  }

  @media screen and (min-width: 1200px) and (max-width: 1299px) {
    // left: 0;
    top: -136.5%;
  }

  @media screen and (min-width: 1070px) and (max-width: 1199px) {
    // left: 0;
    top: -141%;
  }

  @media screen and (min-width: 1020px) and (max-width: 1069px) {
    // left: 0;
    top: -132%;
  }

  @media screen and (min-width: 992px) and (max-width: 1019px) {
    // left: 0;
    top: -137%;
  }

  @media screen and (min-width: 855px) and (max-width: 991px) {
    // left: 0;
    top: -138%;
  }

  @media screen and (min-width: 768px) and (max-width: 854px) {
    // left: 0;
    top: -132.5%;
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    // left: 0;
    top: -140%;
  }

  @media screen and (min-width: 500px) and (max-width: 575px) {
    // left: 0;
    top: -134%;
  }

  @media screen and (min-width: 450px) and (max-width: 499px) {
    left: 5%;
    top: -150%;
  }

  @media screen and (min-width: 410px) and (max-width: 449px) {
    left: 5%;
    top: -140%;
  }

  @media screen and (min-width: 400px) and (max-width: 409px) {
    left: 5%;
    top: -148%;
  }

  @media screen and (min-width: 376px) and (max-width: 399px) {
    left: 5%;
    top: -140%;
  }

  @media screen and (min-width: 345px) and (max-width: 375px) {
    left: 5%;
    top: -148%;
  }

  @media screen and (min-width: 330px) and (max-width: 344px) {
    left: 5%;
    top: -143%;
  }

  @media screen and (max-width: 329px) {
    left: 5%;
    top: -125%;
  }
}

.experience-struggling-girl-img {
  @media screen and (min-width: 1300px) {
    height: 220px;
  }

  @media screen and (min-width: 1200px) and (max-width: 1299px) {
    height: 210px;
  }

  @media screen and (min-width: 1070px) and (max-width: 1199px) {
    height: 200px;
  }

  @media screen and (min-width: 1020px) and (max-width: 1069px) {
    height: 190px;
  }

  @media screen and (min-width: 992px) and (max-width: 1019px) {
    height: 180px;
  }

  @media screen and (min-width: 855px) and (max-width: 991px) {
    height: 170px;
  }

  @media screen and (min-width: 768px) and (max-width: 854px) {
    height: 200px;
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    height: 190px;
  }

  @media screen and (min-width: 500px) and (max-width: 575px) {
    height: 170px;
  }

  @media screen and (min-width: 450px) and (max-width: 499px) {
    height: 160px;
  }

  @media screen and (min-width: 330px) and (max-width: 449px) {
    height: 150px;
  }

  @media screen and (max-width: 329px) {
    height: 145px;
  }
}

.experience-struggling-img-wrapper {
  position: relative;
  left: 55%;

  @media screen and (min-width: 1300px) {
    top: -49%;
  }

  @media screen and (min-width: 1070px) and (max-width: 1299px) {
    top: -57%;
  }

  @media screen and (min-width: 1020px) and (max-width: 1069px) {
    top: -53.5%;
  }

  @media screen and (min-width: 992px) and (max-width: 1019px) {
    top: -57.5%;
  }

  @media screen and (min-width: 855px) and (max-width: 991px) {
    top: -60%;
  }

  @media screen and (min-width: 768px) and (max-width: 854px) {
    top: -57%;
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    top: -59%;
  }

  @media screen and (min-width: 500px) and (max-width: 575px) {
    top: -58%;
  }

  @media screen and (min-width: 450px) and (max-width: 499px) {
    top: -70%;
    left: 65%;
  }

  @media screen and (min-width: 410px) and (max-width: 449px) {
    top: -62%;
    left: 75%;
  }

  @media screen and (min-width: 400px) and (max-width: 409px) {
    top: -70%;
    left: 75%;
  }

  @media screen and (min-width: 376px) and (max-width: 399px) {
    top: -57%;
    left: 75%;
  }

  @media screen and (min-width: 369px) and (max-width: 375px) {
    top: -65%;
    left: 75%;
  }

  @media screen and (min-width: 351px) and (max-width: 368px) {
    top: -64%;
    left: 75%;
  }

  @media screen and (min-width: 345px) and (max-width: 350px) {
    top: -68%;
    left: 75%;
  }

  @media screen and (min-width: 330px) and (max-width: 344px) {
    top: -63%;
    left: 75%;
  }

  @media screen and (max-width: 329px) {
    top: -40%;
    left: 65%;
  }
}

.experience-struggling-img {
  @media screen and (min-width: 1300px) {
    height: 150px;
  }

  @media screen and (min-width: 1200px) and (max-width: 1299px) {
    height: 140px;
  }

  @media screen and (min-width: 1070px) and (max-width: 1199px) {
    height: 120px;
  }

  @media screen and (min-width: 1020px) and (max-width: 1069px) {
    height: 130px;
  }

  @media screen and (min-width: 500px) and (max-width: 1019px) {
    height: 120px;
  }

  @media screen and (max-width: 499px) {
    height: 80px;
  }
}

//decision making item
.experience-make-decision-item-padding {
  @media screen and (min-width: 1300px) {
    padding-top: 60px;
  }

  @media screen and (min-width: 1200px) and (max-width: 1299px) {
    padding-top: 40px;
  }

  @media screen and (min-width: 855px) and (max-width: 1199px) {
    padding-top: 35px;
  }

  @media screen and (min-width: 500px) and (max-width: 854px) {
    padding-top: 30px;
  }

  @media screen and (min-width: 450px) and (max-width: 499px) {
    padding-top: 25px;
  }

  @media screen and (min-width: 400px) and (max-width: 449px) {
    padding-top: 35px;
  }

  @media screen and (min-width: 396px) and (max-width: 399px) {
    padding-top: 45px;
  }

  @media screen and (min-width: 354px) and (max-width: 395px) {
    padding-top: 40px;
  }

  @media screen and (min-width: 352px) and (max-width: 353px) {
    padding-top: 25px;
  }

  @media screen and (min-width: 347px) and (max-width: 351px) {
    padding-top: 15px;
  }

  @media screen and (min-width: 347px) and (max-width: 346px) {
    padding-top: 25px;
  }

  @media screen and (min-width: 335px) and (max-width: 346px) {
    padding-top: 15px;
  }

  @media screen and (min-width: 330px) and (max-width: 334px) {
    padding-top: 5px;
  }

  @media screen and (max-width: 329px) {
    padding-top: 35px;
  }
}

.experience-make-decision-img-wrapper {
  position: relative;
  left: 0;

  @media screen and (min-width: 1300px) {
    top: -138%;
  }

  @media screen and (min-width: 1200px) and (max-width: 1299px) {
    top: -137%;
  }

  @media screen and (min-width: 1070px) and (max-width: 1199px) {
    top: -138.5%;
  }

  @media screen and (min-width: 1020px) and (max-width: 1069px) {
    top: -136.5%;
  }

  @media screen and (min-width: 992px) and (max-width: 1019px) {
    top: -139%;
  }

  @media screen and (min-width: 855px) and (max-width: 991px) {
    top: -140%;
  }

  @media screen and (min-width: 768px) and (max-width: 854px) {
    top: -133.5%;
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    top: -136%;
  }

  @media screen and (min-width: 500px) and (max-width: 575px) {
    top: -136.5%;
  }

  @media screen and (min-width: 475px) and (max-width: 499px) {
    top: -142%;
    left: 5%;
  }

  @media screen and (min-width: 468px) and (max-width: 474px) {
    top: -150%;
    left: 5%;
  }

  @media screen and (min-width: 450px) and (max-width: 467px) {
    top: -150%;
    left: 5%;
  }

  @media screen and (min-width: 445px) and (max-width: 449px) {
    top: -143%;
    left: 5%;
  }

  @media screen and (min-width: 396px) and (max-width: 444px) {
    top: -150%;
    left: 5%;
  }

  @media screen and (min-width: 354px) and (max-width: 395px) {
    top: -146%;
    left: 5%;
  }

  @media screen and (min-width: 347px) and (max-width: 353px) {
    top: -141%;
    left: 5%;
  }

  @media screen and (min-width: 330px) and (max-width: 346px) {
    top: -138%;
    left: 5%;
  }

  @media screen and (max-width: 329px) {
    top: -131%;
    left: 5%;
  }
}

.experience-make-decision-img {
  @media screen and (min-width: 1300px) {
    height: 200px;
  }

  @media screen and (min-width: 1200px) and (max-width: 1299px) {
    height: 180px;
  }

  @media screen and (min-width: 1020px) and (max-width: 1199px) {
    height: 170px;
  }

  @media screen and (min-width: 992px) and (max-width: 1019px) {
    height: 160px;
  }

  @media screen and (min-width: 855px) and (max-width: 991px) {
    height: 150px;
  }

  @media screen and (min-width: 576px) and (max-width: 854px) {
    height: 170px;
  }

  @media screen and (min-width: 500px) and (max-width: 575px) {
    height: 160px;
  }

  @media screen and (min-width: 450px) and (max-width: 499px) {
    height: 150px;
  }

  @media screen and (min-width: 347px) and (max-width: 449px) {
    height: 140px;
  }

  @media screen and (max-width: 346px) {
    height: 130px;
  }
}

.experience-never-give-up-img-wrapper {
  position: relative;

  @media screen and (min-width: 1300px) {
    left: 86%;
    top: -40%;
  }

  @media screen and (min-width: 1200px) and (max-width: 1299px) {
    left: 85%;
    top: -44%;
  }

  @media screen and (min-width: 1070px) and (max-width: 1199px) {
    left: 84%;
    top: -47%;
  }

  @media screen and (min-width: 1020px) and (max-width: 1069px) {
    left: 85%;
    top: -45%;
  }

  @media screen and (min-width: 992px) and (max-width: 1019px) {
    left: 84%;
    top: -47%;
  }

  @media screen and (min-width: 855px) and (max-width: 991px) {
    left: 83%;
    top: -49%;
  }

  @media screen and (min-width: 768px) and (max-width: 854px) {
    left: 84%;
    top: -45%;
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    left: 83%;
    top: -47%;
  }

  @media screen and (min-width: 500px) and (max-width: 575px) {
    left: 86%;
    top: -45%;
  }

  @media screen and (min-width: 450px) and (max-width: 499px) {
    left: 70%;
    top: -60%;
  }

  @media screen and (min-width: 400px) and (max-width: 449px) {
    left: 75%;
    top: -62%;
  }

  @media screen and (min-width: 386px) and (max-width: 399px) {
    left: 70%;
    top: -50%;
  }

  @media screen and (min-width: 357px) and (max-width: 385px) {
    left: 75%;
    top: -50%;
  }

  @media screen and (min-width: 330px) and (max-width: 358px) {
    left: 75%;
    top: -48%;
  }

  @media screen and (max-width: 329px) {
    left: 70%;
    top: -42%;
  }
}

.experience-never-give-up-img {
  @media screen and (min-width: 1070px) {
    height: 130px;
  }

  @media screen and (min-width: 855px) and (max-width: 1069px) {
    height: 120px;
  }

  @media screen and (min-width: 576px) and (max-width: 854px) {
    height: 130px;
  }

  @media screen and (min-width: 400px) and (max-width: 575px) {
    height: 100px;
  }

  @media screen and (max-width: 399px) {
    height: 80px;
  }
}

//web dev item
.experience-web-item-padding {
  @media screen and (min-width: 1300px) {
    padding-top: 120px;
  }

  @media screen and (min-width: 1200px) and (max-width: 1299px) {
    padding-top: 110px;
  }

  @media screen and (min-width: 1070px) and (max-width: 1199px) {
    padding-top: 90px;
  }

  @media screen and (min-width: 1020px) and (max-width: 1069px) {
    padding-top: 100px;
  }

  @media screen and (min-width: 855px) and (max-width: 1019px) {
    padding-top: 90px;
  }

  @media screen and (min-width: 768px) and (max-width: 854px) {
    padding-top: 100px;
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    padding-top: 90px;
  }

  @media screen and (min-width: 500px) and (max-width: 575px) {
    padding-top: 80px;
  }

  @media screen and (min-width: 450px) and (max-width: 499px) {
    padding-top: 75px;
  }

  @media screen and (min-width: 400px) and (max-width: 449px) {
    padding-top: 75px;
  }

  @media screen and (max-width: 399px) {
    padding-top: 90px;
  }
}

.experience-web-study-img-wrapper {
  position: relative;
  left: 20%;

  @media screen and (min-width: 1300px) {
    top: -161%;
  }

  @media screen and (min-width: 1200px) and (max-width: 1299px) {
    top: -165%;
  }

  @media screen and (min-width: 1070px) and (max-width: 1199px) {
    top: -156%;
  }

  @media screen and (min-width: 992px) and (max-width: 1069px) {
    top: -165.5%;
  }

  @media screen and (min-width: 855px) and (max-width: 991px) {
    top: -167%;
  }

  @media screen and (min-width: 768px) and (max-width: 854px) {
    top: -161%;
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    top: -166%;
  }

  @media screen and (min-width: 500px) and (max-width: 575px) {
    top: -161%;
  }

  @media screen and (min-width: 450px) and (max-width: 499px) {
    top: -170%;
    left: 30%;
  }

  @media screen and (min-width: 422px) and (max-width: 449px) {
    top: -164%;
    left: 30%;
  }

  @media screen and (min-width: 400px) and (max-width: 421px) {
    top: -173%;
    left: 30%;
  }

  @media screen and (min-width: 371px) and (max-width: 399px) {
    top: -160%;
    left: 25%;
  }

  @media screen and (min-width: 354px) and (max-width: 370px) {
    top: -168%;
    left: 25%;
  }

  @media screen and (min-width: 349px) and (max-width: 353px) {
    top: -175%;
    left: 25%;
  }

  @media screen and (min-width: 330px) and (max-width: 348px) {
    top: -177%;
    left: 20%;
  }

  @media screen and (max-width: 329px) {
    top: -145%;
    left: 20%;
  }
}

.experience-web-study-img {
  @media screen and (min-width: 1200px) {
    height: 320px;
  }

  @media screen and (min-width: 1020px) and (max-width: 1199px) {
    height: 300px;
  }

  @media screen and (min-width: 992px) and (max-width: 1019px) {
    height: 280px;
  }

  @media screen and (min-width: 855px) and (max-width: 991px) {
    height: 260px;
  }

  @media screen and (min-width: 576px) and (max-width: 854px) {
    height: 300px;
  }

  @media screen and (min-width: 500px) and (max-width: 575px) {
    height: 260px;
  }

  @media screen and (max-width: 499px) {
    height: 230px;
  }
}

//web developer item
.experience-web-developer-item-padding {
  @media screen and (min-width: 1300px) {
    padding-top: 60px;
  }

  @media screen and (min-width: 1200px) and (max-width: 1299px) {
    padding-top: 40px;
  }

  @media screen and (min-width: 855px) and (max-width: 1199px) {
    padding-top: 35px;
  }

  @media screen and (min-width: 500px) and (max-width: 854px) {
    padding-top: 30px;
  }

  @media screen and (min-width: 450px) and (max-width: 499px) {
    padding-top: 25px;
  }

  @media screen and (min-width: 400px) and (max-width: 449px) {
    padding-top: 35px;
  }

  @media screen and (min-width: 396px) and (max-width: 399px) {
    padding-top: 45px;
  }

  @media screen and (min-width: 354px) and (max-width: 395px) {
    padding-top: 40px;
  }

  @media screen and (min-width: 352px) and (max-width: 353px) {
    padding-top: 25px;
  }

  @media screen and (min-width: 347px) and (max-width: 351px) {
    padding-top: 15px;
  }

  @media screen and (min-width: 347px) and (max-width: 346px) {
    padding-top: 25px;
  }

  @media screen and (min-width: 335px) and (max-width: 346px) {
    padding-top: 15px;
  }

  @media screen and (min-width: 330px) and (max-width: 334px) {
    padding-top: 5px;
  }

  @media screen and (max-width: 329px) {
    padding-top: 35px;
  }
}

.experience-web-developer-img {
  @media screen and (min-width: 576px) {
    height: 150px;
  }

  @media screen and (max-width: 575px) {
    height: 130px;
  }
}

.experience-web-developer-img-wrapper {
  position: relative;
  left: 35%;

  @media screen and (min-width: 1300px) {
    top: -125%;
  }

  @media screen and (min-width: 1200px) and (max-width: 1299px) {
    top: -128%;
  }

  @media screen and (min-width: 1070px) and (max-width: 1199px) {
    top: -131%;
  }

  @media screen and (min-width: 1020px) and (max-width: 1069px) {
    left: 30%;
    top: -129%;
  }

  @media screen and (min-width: 992px) and (max-width: 1019px) {
    left: 30%;
    top: -134%;
  }

  @media screen and (min-width: 855px) and (max-width: 991px) {
    left: 30%;
    top: -137%;
  }

  @media screen and (min-width: 768px) and (max-width: 854px) {
    top: -126%;
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    top: -129%;
  }

  @media screen and (min-width: 500px) and (max-width: 575px) {
    top: -126%;
  }

  @media screen and (min-width: 486px) and (max-width: 499px) {
    top: -125%;
  }

  @media screen and (min-width: 450px) and (max-width: 485px) {
    top: -133%;
  }

  @media screen and (min-width: 411px) and (max-width: 449px) {
    top: -137%;
  }

  @media screen and (min-width: 400px) and (max-width: 410px) {
    top: -144%;
  }

  @media screen and (min-width: 396px) and (max-width: 399px) {
    top: -137%;
  }

  @media screen and (min-width: 354px) and (max-width: 395px) {
    top: -133%;
  }

  @media screen and (min-width: 390px) and (max-width: 395px) {
    top: -133%;
  }

  @media screen and (min-width: 354px) and (max-width: 389px) {
    left: 30%;
    top: -140%;
  }

  @media screen and (min-width: 352px) and (max-width: 353px) {
    left: 30%;
    top: -129%;
  }

  @media screen and (min-width: 351px) and (max-width: 351px) {
    left: 30%;
    top: -122%;
  }

  @media screen and (min-width: 343px) and (max-width: 350px) {
    left: 30%;
    top: -129%;
  }

  @media screen and (min-width: 335px) and (max-width: 342px) {
    left: 30%;
    top: -135%;
  }

  @media screen and (max-width: 334px) {
    left: 30%;
    top: -129%;
  }
}
